import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames"
import * as styles from "./about-author.module.scss"

export default function AboutAuthor({ name, description, photo, className }) {
  return (
    <div className={cn(styles.author, className)}>
      <p className={styles.title}>About author</p>
      <div className={styles.content}>
        <div className={styles.photo}>
          <GatsbyImage image={photo} alt={name} />
        </div>
        <div>
          <p className={styles.name}>{name}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  );
}
