import React, { useRef } from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Link from "../../components/link"
import { GatsbyImage } from "gatsby-plugin-image"
import Title from "../../components/title"
import * as styles from "./post.module.scss"
import Share from "../../components/share"
import AboutAuthor from "../../components/about-author"

export default function PostTemplate({ data: { post, category, author } }) {
  const articleElementRef = useRef()

  return (
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
        image={post.frontmatter.featured_image}
        article
      />
      <Layout
        showHeaderCtaButton
        readingIndicatorElementRef={
          post.timeToRead > 1 ? articleElementRef : null
        }
        articleHeader={post.timeToRead > 1 ? post : null}
      >
        <article ref={articleElementRef} className={styles.post}>
          <header className={styles.header}>
            <div className={styles.subHeader}>
              <Link to="/blog">Back to blog</Link>
              <p>{post.timeToRead} min to read</p>
            </div>

            <Title headingLevel="h1" className={styles.title}>
              {post.frontmatter.title}
            </Title>

            <Title headingLevel="p" className={styles.excerpt}>
              {post.excerpt}
            </Title>

            <div className={styles.author}>
              <GatsbyImage
                image={author.frontmatter.photo.sharp.gatsbyImageData}
                alt={author.frontmatter.title}
              />
              <p>
                <span>
                  By <strong>{author.frontmatter.title}</strong>
                </span>
                <span>{post.frontmatter.date}</span>
              </p>
            </div>

            <div className={styles.featuredImage}>
              <GatsbyImage
                image={post.frontmatter.image.sharp.gatsbyImageData}
                alt={post.frontmatter.title}
              />
            </div>
          </header>
          <div className={styles.postBody}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
          <footer>
            <Share
              slug={`blog/${post.frontmatter.slug}`}
              title={post.frontmatter.title}
              className={styles.share}
            />
            <AboutAuthor
              name={author.frontmatter.title}
              description={author.frontmatter.description}
              photo={author.frontmatter.photo.sharp.gatsbyImageData}
              className={styles.aboutAuthor}
            />
          </footer>
        </article>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!, $category: String!, $author: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        draft
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        image: featured_image {
          sharp: childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
      excerpt
      body
      timeToRead
    }
    category: mdx(fileAbsolutePath: { glob: $category }) {
      frontmatter {
        title
        slug
        description
      }
    }
    author: mdx(fileAbsolutePath: { glob: $author }) {
      frontmatter {
        title
        slug
        description
        photo {
          sharp: childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
