// extracted by mini-css-extract-plugin
export var post = "post-module--post--wCJXg";
export var header = "post-module--header--1Q7RO";
export var subHeader = "post-module--subHeader--dksYC";
export var title = "post-module--title--2Vt6H";
export var excerpt = "post-module--excerpt--yvFV9";
export var author = "post-module--author--34kxe";
export var featuredImage = "post-module--featuredImage--36w8R";
export var postBody = "post-module--postBody--nnejY";
export var share = "post-module--share--3auUb";
export var aboutAuthor = "post-module--aboutAuthor--2O-NW";